<template>
    <Layout>
        <div id="page" class="card">
            <div class="card-header">
                <div class="card-title">
                    Resposta de Formulário
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-4" v-for="question in form.questions" :key="'question-' + question.id">
                       <b v-html="question.question"></b>
                        <div v-if="question.type === 1" class="mb-3">
                            {{answers[question.id]}}
                        </div>
                        <div v-else>
                        <p v-for="alternative in question.alternatives" :key="'alternative-' + alternative.id"
                        >
                            <span :class="answers[question.id].indexOf(alternative.id) > -1 ? 'fw-bold' : ''">{{alternative.alternative}}</span>
                        </p>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <label for="feedback" class="form-label">Feedback <span class="text-danger">*</span></label>
                        <textarea id="feedback" v-model="feedback" rows="10" class="form-control"></textarea>
                    </div>
                    <div class="col-12 text-end mb-3">
                        <button type="button" class="btn btn-outline-primary" @click="save">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "@/components/layout/main.vue";
import http from '@/http'
import {endLoading, startLoading} from "@/components/composables/spinners";

export default {
    components: {Layout},
    data() {
        return {
            form: [],
            answers: [],
            feedback: ''
        }
    },

    methods: {
        getData() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page');
            const data = {
                form_id: this.$route.params.form,
                content_id: this.$route.params.content,
                mentee_id: this.$route.params.mentee,
            }

            http.post('forms/by-mentee/', data)
                .then(response => {
                    this.form = response.data.form;
                    this.answers = response.data.answers;
                })
                .catch(e => {
                    console.error('Data Form: ', e.response);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                    endLoading('page')
                })
        },


        getFeedback() {
            const formData = {
                mentee_id: this.$route.params.mentee,
                section_content_id: this.$route.params.content,
            }

            http.post('form-answer/get-feedback', formData)
                .then((response) => {
                    this.feedback = response.data.feedback;
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        },

        save() {
            const formData = {
                feedback: this.feedback,
                mentee_id: this.$route.params.mentee,
                section_content_id: this.$route.params.content,
            }

            http.post('form-answer/feedback', formData)
                .then(() => {
                    this.$notifySuccess('Feedback salvo com sucesso!');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 500)
                })
                .catch(e => {
                    console.error('Get Tasks Mentoring: ', e)
                })
        }
    },

    mounted() {
        this.getData();
        this.getFeedback();
    }
}

</script>

<style>

.fw-bold {
    font-weight: bold;
}

</style>
